import { useEffect } from 'react'
import { adminClient, useAdminToken } from '@repo/utils'
import { onChange } from './functions'
import { setUserSelector, useAuthStore } from './use-auth'

const setTokenSelector = (s) => s.setToken

export function FirebaseConfig() {
  const setUser = useAuthStore(setUserSelector)
  const setToken = useAdminToken(setTokenSelector)

  useEffect(() => {
    const unsubscribe = onChange((user) => {
      if (user) {
        user.getIdTokenResult(true).then((result) => {
          adminClient.setHeader('authorization', `Bearer ${result.token}`)
          setToken(result.token)
          setUser({ ...user, authToken: result.token })
        })
      } else {
        setUser(null)
        setToken(null)
        adminClient.setHeader('authorization', '')
      }
    })

    return () => {
      unsubscribe()
    }
  }, [setToken, setUser])

  return null
}
