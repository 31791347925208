export enum FormattedDateType {
  SHORT = 'short',
  LONG = 'long',
}

export function isValidDate(date: Date) {
  return date && date instanceof Date && !isNaN(date.getTime())
}

export const getFormattedDate = (
  date: Date | string,
  type?: FormattedDateType
) => {
  if (!date) return ''

  if (typeof date === 'string') date = new Date(date)

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }

  switch (type) {
    case FormattedDateType.SHORT:
      const day = date.toLocaleString('es-ES', { day: 'numeric' })
      const month = date.toLocaleString('es-ES', { month: 'long' })
      const year = date.toLocaleString('es-ES', { year: 'numeric' })

      return `${day} ${month} ${year}`
    default:
      const formatoFecha = new Intl.DateTimeFormat('es-ES', options)

      return formatoFecha.format(date)
  }
}

export const getTimeAgo = (date: Date) => {
  const now = new Date()
  const diff = (now as any) - (date as any)

  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (years > 0) {
    return 'Hace ' + years + (years === 1 ? ' año' : ' años')
  } else if (months > 0) {
    return 'Hace ' + months + (months === 1 ? ' mes' : ' meses')
  } else if (days > 0) {
    return 'Hace ' + days + (days === 1 ? ' día' : ' días')
  } else if (hours > 0) {
    return 'Hace ' + hours + (hours === 1 ? ' hora' : ' horas')
  } else if (minutes > 0) {
    return 'Hace ' + minutes + (minutes === 1 ? ' minuto' : ' minutos')
  } else {
    return 'Hace unos segundos'
  }
}

export const addHoursToDate = (date: Date, hours: number) => {
  return new Date(date.getTime() + 60 * 60 * 1000 * hours)
}

export const isRecent = (timestamp: Date, minutes) => {
  const diferenciaEnMs = new Date().getTime() - timestamp.getTime()
  const cincoMinutosEnMs = minutes * 60 * 1000

  return diferenciaEnMs < cincoMinutosEnMs
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Calculates the duration between two dates and returns a formatted string with hours, minutes and seconds.
 * @param createdAt
 * @param updatedAt
 */
export function formatTimeDifference(createdAt: Date, updatedAt: Date): string {
  const diffInMilliseconds = updatedAt.getTime() - createdAt.getTime()

  // Calculate the differences in seconds, minutes, and hours
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
  const hours = Math.floor(diffInSeconds / 3600)
  const minutes = Math.floor((diffInSeconds % 3600) / 60)
  const seconds = diffInSeconds % 60

  // Build the formatted string
  const parts: string[] = []
  if (hours > 0) parts.push(`${hours}h`)
  if (minutes > 0) parts.push(`${minutes}m`)
  if (seconds > 0 || parts.length === 0) parts.push(`${seconds}s`)

  return parts.join(' ')
}

export function getTimeElapsed(date: Date): string {
  const now = new Date()

  if (date.toDateString() === now.toDateString()) {
    return 'Hoy'
  }

  const yesterday = new Date(now)
  yesterday.setDate(yesterday.getDate() - 1)
  if (date.toDateString() === yesterday.toDateString()) {
    return 'Ayer'
  }

  const startOfWeek = new Date(now)
  startOfWeek.setDate(now.getDate() - now.getDay())
  if (date >= startOfWeek) {
    return 'Esta semana'
  }

  const startOfLastWeek = new Date(startOfWeek)
  startOfLastWeek.setDate(startOfLastWeek.getDate() - 7)
  if (date >= startOfLastWeek) {
    return 'Semana pasada'
  }

  if (
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    return 'Este mes'
  }

  const lastMonth = new Date(now)
  lastMonth.setMonth(lastMonth.getMonth() - 1)
  if (
    date.getMonth() === lastMonth.getMonth() &&
    date.getFullYear() === lastMonth.getFullYear()
  ) {
    return 'Mes pasado'
  }

  const sixMonthsAgo = new Date(now)
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6)
  if (date > sixMonthsAgo) {
    return 'Más de 1 mes'
  }

  const oneYearAgo = new Date(now)
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
  if (date > oneYearAgo) {
    return 'Más de 6 meses'
  }

  return 'Más de 1 año'
}
