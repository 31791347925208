'use client'
// import { signIn, useAuth } from '@/services/auth'
import { LoginCommonProps, LoginUserForm } from '@repo/account/types'
import { z } from 'zod'
import { useRouter } from 'next/navigation'
import { signIn, useSession } from 'next-auth/react'
import { useState } from 'react'
import { setUserSelector, useAuthStore } from '@/services/auth'
import { adminClient, useAdminToken } from '@repo/utils'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { pki, util } from 'node-forge'
import { AccountLayout } from '@repo/ui/components/accounts/account-layout'
import { TextField } from '@repo/ui/components/forms/text-field'
import { Spinner } from '@repo/ui'

const schema = z.object({
  email: z.coerce.string().email(),
  password: z.coerce.string(),
})

export function Login({ pemKey }: LoginCommonProps) {
  const router = useRouter()
  const { status, data: session } = useSession()
  const [isSubmit, setIsSubmit] = useState(false)
  const setUser = useAuthStore(setUserSelector)
  const setToken = useAdminToken((s) => s.setToken)
  const currentToken = useAdminToken((s) => s.token)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{
    email: string
    password: string
  }>({
    resolver: zodResolver(schema),
  })

  if (session && session.user && (session.user as any).token) {
    const token = (session.user as any).token

    adminClient.setHeader('authorization', `Bearer ${token}`)
    setToken(token)
    setUser({ ...session.user, authToken: token })
  }

  if (currentToken === (session?.user as any)?.token) {
    router.push('/')
  }

  const onSubmit = async (data: LoginUserForm) => {
    try {
      if (isSubmit) return
      setIsSubmit(true)
      const publicKey = pki.publicKeyFromPem(pemKey)

      const res = await signIn('credentials', {
        email: data.email,
        password: util.encode64(publicKey.encrypt(data.password)),
        redirect: false,
      })

      // tracking.trackEvent({
      //   eventName: 'signin',
      //   parameters: {},
      // })

      if (res.error) {
        setError('password', { message: res.error })
      }
    } catch (ex) {
      setError('password', { message: ex.message })
    }
    setIsSubmit(false)
  }

  return (
    <AccountLayout>
      <div className="flex h-full w-full items-center justify-center py-16">
        <form
          className="flex h-[452px] w-full max-w-[500px] flex-col justify-center rounded-[12px] bg-white px-[20px] py-[40px] font-sans shadow-[0px_2px_6px_#EBEBF5] sm:px-[48px] sm:py-[56px]"
          onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-[20px] flex w-full">
            <h1 className="text-[20px] font-semibold">
              {'Accede a tu cuenta'}
            </h1>
          </div>
          <div className="mb-[24px]">
            <TextField
              name={'email'}
              type={'email'}
              label={'Correo electrónico'}
              placeholder={'Correo electrónico...'}
              {...register('email')}
              className="bg-[#F7F7F7]"
              error={errors.email?.message}
            />
          </div>
          <div className="mb-[32px]">
            <TextField
              name={'password'}
              type={'password'}
              placeholder={'Contraseña...'}
              label={'Contraseña'}
              // helper={{
              //   link: '/account/email',
              //   text: 'He olvidado la contraseña',
              // }}
              error={errors.password?.message}
              {...register('password')}
              className={'bg-[#F7F7F7]'}
            />
          </div>
          <div className="flex flex-col">
            <button
              disabled={isSubmit}
              className="flex h-[42px] items-center justify-center rounded-3xl bg-[#5368E5] text-[16px] leading-[19px] text-white"
              type="submit">
              {isSubmit || status === 'authenticated' ? (
                <Spinner color="white" />
              ) : (
                'Iniciar sesión'
              )}
            </button>
          </div>
        </form>
      </div>
    </AccountLayout>
  )
}
